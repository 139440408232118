import React from 'react';

export default function InventoryDisplay() {
  return (
    <div>
      <h2>
        Inventory not yet implemented!
      </h2>
    </div>
  );
}